.quicklog-builder-container {
    background: linear-gradient(to bottom, rgb(207, 221, 231) 0%, rgb(207, 221, 231) 100%);
    position: absolute;
    min-height: 300px;
    top: 20%;
    left: 22%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.72);
}

.quicklog-builder-container:focus {
    outline:none;
}

.textarea-container {
    display: inline-block;
    padding: 20px;
}

.quicklog-textarea {
    width: 400px;
    height: 300px;
}

.save-button-container {
    padding: 5px;
}